import { Pipe, PipeTransform } from '@angular/core';
import { formatDateTimeToUi } from '../../utils/date.util';

@Pipe({
  name: 'georgianDateTimeUi',
  standalone: true,
})
export class GeorgianDateTimeUiPipe implements PipeTransform {
  transform(date?: string | Date | null): string {
    if (!date) return '';
    return formatDateTimeToUi(date);
  }
}
